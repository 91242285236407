const Faqs = [
  {
    id: 1,
    question: "What is FrontenLab?",
    answer:
      "FrontenLab is a platform that offers free frontend challenges to help you practice and enhance your web development skills. We provide design templates and Figma files for you to replicate. By submitting your solutions, you earn points and can be featured on our leaderboard.",
  },
  {
    id: 2,
    question: "How do the frontend challenges work?",
    answer:
      "Each challenge consists of a design that we provide. Your task is to replicate the design as closely as possible using HTML, CSS, and JavaScript. Once you complete your design, you can submit it through our platform. Your solution will be reviewed, and you'll earn points based on the accuracy and quality of your replication.",
  },
  {
    id: 3,
    question: "Do I need to pay for access to Figma designs or challenges?",
    answer:
      "No, all Figma designs and challenges on FrontenLab are completely free. We believe in providing valuable resources at no cost to help you improve your frontend skills.",
  },
  {
    id: 4,
    question: "How can I get on the leaderboard?",
    answer:
      "To appear on the leaderboard, you need to participate in our challenges and submit your solutions. You'll earn points based on the quality and accuracy of your work. We also host weekly competitions where you can earn additional points by participating and completing the challenge. The more points you accumulate, the higher your rank on the leaderboard.",
  },
  {
    id: 5,
    question: "Can I use my own design tools to complete the challenges?",
    answer:
      "While we provide Figma designs, you're welcome to use any design tools you're comfortable with to complete the challenges. However, you should ensure that your final submission matches the provided design as closely as possible.",
  },
];

export default Faqs;
