import React from 'react'
import './Terms.css'
import Tac from '../../Components/Common/TermsAndCondition/Tac'
import Navbar from '../../Components/Common/Navbar/Navbar'

const Terms = () => {
  return (
    <div className="Terms">
        <Navbar />
        <Tac />
    </div>
  )
}

export default Terms