import React from 'react'
import PrivacyPolicy from '../../Components/Common/PrivacyPolicy/PrivacyPolicy'
import Navbar from '../../Components/Common/Navbar/Navbar'

const Privacy = () => {
  return (
    <div className="Privacy">
        <Navbar />
        <PrivacyPolicy /> 
    </div>
  )
}

export default Privacy