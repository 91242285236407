import React from 'react'
import './Leaderboard.css'
import LeaderboardContent from '../../Components/Common/Leaderboard/LeaderboardContent'

const Leaderboard = () => {
  return (
    <LeaderboardContent />
  )
}

export default Leaderboard