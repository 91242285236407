import NO1 from '../Assets/Images/NO1.png'
import NO2 from '../Assets/Images/NO2.png'
import NO3 from '../Assets/Images/NO3.png'
import HIW1 from '../Assets/Images/HIW1.png'
import HIW2 from '../Assets/Images/HIW2.png'
import HIW3 from '../Assets/Images/HIW3.png'

const HiwContent = [
    {
        id: 1,
        no: NO1,
        description: "Select from a variety of engaging frontend development challenges.",
        img: HIW1
    },
    {
        id: 2,
        no: NO2,
        description: "Dive into the challenge and apply your skills to solve real-world problems.",
        img: HIW2
    },
    {
        id: 3,
        no: NO3,
        description: "Submit your solution and earn points based on your performance.",
        img: HIW3
    }
];

export default HiwContent;